import { Exclude, Expose } from "class-transformer";

import { AusgabenDataModel } from "./AusgabenDataModel";
import { BaseType } from "./util/BaseType";
import Datum from "@/util/datum";
import { FilialeModel } from "./FilialeModel";
import { ProduktModel } from "./ProduktModel";
import { ZeitschriftFlatModel } from "./ZeitschriftDataModel";
import { api } from '@/util/api';
import { catApi } from '@/util/logging';

export class BuchungenDataModel extends BaseType {

   @Expose() uid = '';
   @Expose() url = '';
   @Expose({ name: "alias_url_1" }) aliasUrl1 = '';
   @Expose({ name: "alias_url_2" }) aliasUrl2 = '';
   @Expose({ name: "obj_id" }) id = '';   // deprecated

   @Expose() jahr = 0;
   @Expose() heft = 0;

   @Expose({ name: "ausgabe_text" }) ausgabe = '';
   @Expose() verkauf = 0.0;

   @Expose() hlfg: number | null = null;
   @Expose() nlfg: number | null = null;
   @Expose() diff: number | null = null;
   @Expose() remi: number | null = null;
   @Expose() abvk: number | null = null;

   @Expose({ name: "bchg_datum" }) buchungsDatum: string | null = null;
   @Expose({ name: "lfg_datum" }) lieferungDatum: string | null = null;
   @Expose({ name: "remi_datum" }) remissionDatum: string | null = null;

   @Exclude() $created = Date.now();

   // === === === === === === === === === === === === === === 

   get wareneingang(): string {

      let ret = 0;
      if (this.hlfg) ret += this.hlfg;
      if (this.nlfg) ret += this.nlfg;
      if (this.diff) ret += this.diff;

      if (ret != 0) return "" + ret
      return "-/-"
   }

   get verkaufStr(): string {

      return BaseType.currencyFormatter.format(this.verkauf)
   }

   get lieferungDatumStr(): string {
      return Datum.fmtTagDatumZeit(Datum.parse(this.lieferungDatum))
   }

   get remissionDatumStr(): string {
      return Datum.fmtTagDatumZeit(Datum.parse(this.remissionDatum))
   }

   // === === === === === === === === === === === === === === 

   static forAusgabeUndFiliale = async (ausg: AusgabenDataModel, filiale: FilialeModel): Promise<BuchungenDataModel | null> => {

      catApi.info("forAusgabeUndFiliale ... querying: " + JSON.stringify(ausg) + " / " + JSON.stringify(filiale));

      try {

         const path = `v1/buchungen${ausg.url}${filiale.url}`
         const response = await api.get<BuchungenDataModel>(path);
         catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);


         if (response.status == 204) {

            return null;
         }

         const ret = BaseType.convert(BuchungenDataModel, response.data)
         return ret
         //
      } catch (e) {

         const err = new Error(JSON.stringify(e))
         catApi.error("caught Error: " + err.message, null);
         return Promise.reject(err)
      }
   }

   static forZeitschriftUndFiliale = async (ztg: ZeitschriftFlatModel | ProduktModel, filiale: FilialeModel, params: BuchungenZuZeitschriftParams = {}): Promise<BuchungenDataModel[] | null> => {

      catApi.info("forZeitschriftUndFiliale ... querying: " + JSON.stringify(ztg) + " / " + JSON.stringify(filiale));

      try {

         const path = `v1/buchungen${ztg.url}${filiale.url}`
         const response = await api.get<BuchungenDataModel>(path, { params: params });
         catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

         if (response.status == 204) {

            return new Array<BuchungenDataModel>();
         }

         const ret = BaseType.convertArray(BuchungenDataModel, response.data)
         return ret
         //
      } catch (e) {

         const err = new Error(JSON.stringify(e))
         catApi.error("caught Error: " + err.message, null);
         return Promise.reject(err)
      }
   }
}

export enum BuchungenZuZeitschriftAnchor {

   LATEST = 1,
   CURRENT_WARENEINGANG,
   CURRENT_BUCHUNG,
   CURRENT_EVT,
   OLDEST
}

export interface BuchungenZuZeitschriftParams {
   direction?: number,
   offset?: number,
   count?: number,

   anchor?: BuchungenZuZeitschriftAnchor,
}