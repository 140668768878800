import { Exclude, Expose } from "class-transformer";

import { BaseType } from "./util/BaseType";
import Datum from "@/util/datum";
import { ZeitschriftFlatModel } from "./ZeitschriftDataModel";
import { api } from "@/util/api";
import { catApi } from "@/util/logging";

export class AusgabenDataModel extends BaseType {
  @Expose() uid = "";
  @Expose() url = "";

  @Expose() id = -1;
  @Expose() ztg = -1;

  @Expose() jahr = 0;
  @Expose() heft = 0;
  @Expose() ausgabe = "";
  @Expose() untertitel = "";

  @Expose() invers = 0;
  @Expose() f_num = 0;

  @Expose() lfg = 0;
  @Expose() remi = 0;
  @Expose() verkauf = 0;

  @Expose() dispo: string | null = null;
  @Expose() evt: string | null = null;
  @Expose() remiDatum: string | null = null;

  shallow = true;

  @Exclude() $created = Date.now();

  // === === === === === === === === === === === === === ===

  get dispoD(): string {
    return Datum.p5sdD(this.dispo, "kein Dispotermin");
  }

  get evtD(): string {
    return Datum.p5sdD(this.evt, "kein EVT");
  }

  get remiD(): string {
    return Datum.p5sdD(this.remiDatum, "kein Remi");
  }

  // === === === === === === === === === === === === === ===

  static deepByID = async (
    id: number,
    deep: number,
    nachbar?: number
  ): Promise<AusgabenDataModel[]> => {
    catApi.debug("deepByID ... querying: " + id);

    try {
      let para: Record<string, number> = { deep: deep == 0 ? 0 : 1 };
      if (nachbar !== undefined) para = { nachbar, ...para };

      const path = `v1/ausgaben/${id}`;
      const response = await api.get<AusgabenDataModel[]>(path, {
        params: para,
      });
      catApi.debug(
        `response: /${path}?${JSON.stringify(para)} -> ${JSON.stringify(
          response
        )}`
      );

      const ret = BaseType.convertArray(AusgabenDataModel, response.data);
      if (deep != 0) {
        for (let index = 0; index < ret.length; index++) {
          ret[index].shallow = false;
        }
      }
      return ret;
      //
    } catch (e) {
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, null);
      return Promise.reject(err);
    }
  };

  static inRange = async (von: string, bis: string): Promise<number[]> => {
    catApi.info("inRange ... querying");

    const path = `v1/ausgaben`;
    const response = await api.get<number[]>(path, {
      params: { von, bis, aktiv: true },
    });

    if (
      api.isError(response) ||
      api.isErrorResponse(response.data) ||
      !Array.isArray(response.data)
    ) {
      catApi.info("Nope! " + JSON.stringify(response));
      throw response;
    }

    return response.data;
  };

  static forZeitschrift = async (
    ztg: ZeitschriftFlatModel,
    params: AusgabenZuZeitschriftParams
  ): Promise<AusgabenDataModel[]> => {
    catApi.info(
      "forZeitschrift ... querying: " +
        JSON.stringify(ztg) +
        " / " +
        JSON.stringify(params)
    );

    try {
      const path = `v1${ztg.url}/ausgaben`;
      const response = await api.get<AusgabenDataModel[]>(path, {
        params: params,
      });
      catApi.info(
        `response: /${path}?${JSON.stringify(params)} -> ${JSON.stringify(
          response
        )}`
      );

      const ret = BaseType.convertArray(AusgabenDataModel, response.data);
      return ret;
      //
    } catch (e) {
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, null);
      return Promise.reject(err);
    }
  };
}

export enum AusgabenZuZeitschriftAnchor {
  LATEST = 1,
  CURRENT_WARENEINGANG,
  CURRENT_BUCHUNG,
  CURRENT_EVT,
  OLDEST,
}

export interface AusgabenZuZeitschriftParams {
  direction?: number;
  offset?: number;
  count?: number;

  anchor?: AusgabenZuZeitschriftAnchor;
}
