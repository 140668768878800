



























































































import {
     AusgabenDataModel,
     AusgabenZuZeitschriftAnchor,
} from "@/models/AusgabenDataModel";
import {
     BuchungenDataModel,
     BuchungenZuZeitschriftAnchor,
} from "@/models/BuchungenDataModel";

import { FilialeModel } from "@/models/FilialeModel";
import { ProduktModel } from "@/models/ProduktModel";
import Vue from "vue";
import { ZeitschriftFlatModel } from "@/models/ZeitschriftDataModel";
import { catVue } from "@/util/logging";
import { mapState } from "vuex";

export default Vue.extend({
     // Component name for reference
     name: "PresseAnzeige",

     created() {
       catVue.info(`${this.$options.name}:: CREATED... `);
     },
     mounted() {
       catVue.info(
         `${this.$options.name}:: MOUNTED... props.produkt -> ${this.produkt.url}`
       );

       this.fetch();
     },
     data: () => ({
       ausgaben: [] as AusgabenDataModel[],
       loading: 0,
       buchungen: new Map<string, BuchungenDataModel | null>(),
       lastInFiliale: new Map<string, BuchungenDataModel | null>(),
     }),
     props: {
       produkt: Object,
     },
     computed: {
       filialen(): FilialeModel[] {
         catVue.info("Called. This.filialenAktiv = " + this.filialenAktiv);
         return this.filialenAktiv;
       },
       ...mapState("auth", ["theFiliale", "filialenAktiv"]),
     },
     methods: {
       //
       buchung(ausgabe: AusgabenDataModel): BuchungenDataModel | null {
         let ret = this.buchungen.get(ausgabe.uid);
         if (ret === undefined) ret = null;
         return ret;
       },
       //
       getLastInFiliale(filiale: FilialeModel): BuchungenDataModel | null {
         let ret = this.lastInFiliale.get(filiale.uid);
         if (ret !== undefined && ret !== null) return ret;
         return null;
       },
       //
       selectFiliale(fil: FilialeModel): void {
         this.$store.commit("auth/setFiliale", fil);
       },
       //
       verkauf(ausgabe: AusgabenDataModel): string | undefined {
         return this.buchung(ausgabe)?.verkaufStr;
       },
       //
       lfgDatum(ausgabe: AusgabenDataModel): string | undefined {
         return this.buchung(ausgabe)?.lieferungDatumStr;
       },
       //
       remiDatum(ausgabe: AusgabenDataModel): string | undefined {
         return this.buchung(ausgabe)?.remissionDatumStr;
       },
       //
       lfg(ausgabe: AusgabenDataModel): number | string {
         const b = this.buchung(ausgabe);
         if (null === b) return "-/-";
         let ret = 0;
         if (null !== b.hlfg) ret += b.hlfg;
         if (null !== b.nlfg) ret += b.nlfg;
         if (null !== b.diff) ret += b.diff;
         return ret;
       },
       remi(ausgabe: AusgabenDataModel): number | string {
         const b = this.buchung(ausgabe);
         if (null === b || null === b.remi) return "-/-";
         return b.remi;
       },
       verk(ausgabe: AusgabenDataModel): number | string {
         const b = this.buchung(ausgabe);
         if (null === b || null === b.abvk) return "-/-";
         return b.abvk;
       },
       //
       async fetch() {
         this.loading++;
         if (null !== this.produkt && undefined !== this.produkt)
           AusgabenDataModel.forZeitschrift(this.produkt as ZeitschriftFlatModel, {
             anchor: AusgabenZuZeitschriftAnchor.CURRENT_EVT,
           })
             .then((ausgaben) => {
               this.ausgaben = [];
               this.ausgaben.push(...ausgaben);

               this.fetchFiliale();
               this.fetchLastBuchung();
               this.loading--;
             })
             .catch((e) => {
               catVue.info("ERROR " + e);
             });
       },
       //
       async fetchFiliale() {
         this.ausgaben.forEach((ausgabe) => {
           this.loading++;
           this.buchungen.set(ausgabe.uid, null);
           BuchungenDataModel.forAusgabeUndFiliale(ausgabe, this.theFiliale)
             .then((buchung) => {
               this.buchungen.set(ausgabe.uid, buchung);
               this.loading--;
             })
             .catch((e) => {
               catVue.info("Error " + e);
               this.loading--;
             });
         });
       },
       //
       async fetchLastBuchung() {
         this.filialen.forEach((filiale) => {
           this.loading++;
           this.lastInFiliale.set(filiale.uid, null);
           BuchungenDataModel.forZeitschriftUndFiliale(this.produkt, filiale, {
             count: 1,
             anchor: BuchungenZuZeitschriftAnchor.CURRENT_WARENEINGANG,
           })
             .then((buchungen) => {
               if (Array.isArray(buchungen) && buchungen.length == 1)
                 this.lastInFiliale.set(filiale.uid, buchungen[0]);
               this.loading--;
             })
             .catch((e) => {
               catVue.info("Error " + e);
               this.loading--;
             });
         });
       },
     },
     watch: {
       theFiliale(to: FilialeModel, from: FilialeModel): void {
         catVue.info(
           `${this.$options.name}:: theFiliale '${from.uid}' > '${to.uid}' `
         );
         this.fetchFiliale();
       },
       produkt(to: ProduktModel, from: ProduktModel): void {
         catVue.info(
           `${this.$options.name}:: produkt '${from.uid}' > '${to.uid}' `
         );
         this.fetch();
       },
     },
   });
